
                  @import "@/styles/utilities/_variables.scss";
                  @import "@/styles/utilities/_mixins.scss";
                  @import "@/styles/utilities/_functions.scss";
                

#app {
  $default-color: #2c3e50;

  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $default-color;
}

.cursor {
  &--pointer {
    cursor: pointer;
  }
}

.outlined {
    border: solid 1px rgba(0, 0, 0, 0.38);

    &--bottom {
      border-bottom: solid 1px rgba(0, 0, 0, 0.38);
    }
}

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

